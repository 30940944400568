'use client';

import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import blukai from '~/helpers/blukai';
import { ReactPixel } from '#/renderer/router';

export default function PathChange() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    blukai.function(window, '__bk_l', window.location.href);
    ReactPixel.pageView();
    if (document.location.pathname !== '/terms' || !window.location.hash) {
      document.getElementById('app').scrollIntoView(true);
    }
  }, [pathname, searchParams]);
}
